import { IInfographicsData } from "./../types/infographics.d";
import { saveAs } from "file-saver";
import { IPage } from "../types/page";
import { IPagination } from "./../types/pagination.d";
import { baseAPI } from "./base";
import { ITwitchChannel, ITwitchStreamsFilters } from "../types/twitch";

const baseAPIEnhanced = baseAPI.enhanceEndpoints({
  addTagTypes: ["Channel", "Post"],
});

const baseURL = "/twitch";
const baseURLChannels = baseURL + "/streams";


export const twitchAPI = baseAPIEnhanced.injectEndpoints({
  endpoints: (builder) => ({
    // Получить все стримы или экспортировать
    getTwitchChannels: builder.query<
      IPage<ITwitchChannel>,
      Partial<ITwitchStreamsFilters & IPagination>
    >({
      query: (params) => ({
        url: `${baseURLChannels}`,
        method: "GET",
        params: {
          ...params,
          sort_field: "stream_name",
        },
      }),
      providesTags: ["Channel"],
    }),

    // Получить удаленные стримы
    getDeletedTwitchChannels: builder.query<
      IPage<ITwitchChannel>,
      Partial<ITwitchStreamsFilters & IPagination>
    >({
      query: (params) => ({
        url: `${baseURLChannels}/deleted`,
        method: "GET",
        params: {
          ...params,
          sort_field: "stream_name",
        },
      }),
      providesTags: ["Channel"],
    }),

    // Экспортировать стримы
    exportTwitchChannels: builder.query<Blob, Partial<ITwitchStreamsFilters>>({
      query: (params) => ({
        url: `${baseURLChannels}/export`,
        method: "GET",
        params,
        responseHandler: (response) => response.blob(),  // Для скачивания файла
      }),
    }),

    // Получить стрим по ID
    getTwitchChannelById: builder.query<ITwitchChannel, string>({
      query: (stream_id) => ({
        url: `${baseURLChannels}/${stream_id}`,
        method: "GET",
      }),
      providesTags: (result, error, stream_id) => [{ type: "Channel", id: stream_id }],
    }),

    // Удалить стримы (bulk)
    bulkDeleteTwitchChannels: builder.mutation<
      { msg: string },
      string[]
    >({
      query: (stream_ids) => ({
        url: `${baseURLChannels}/bulk_delete`,
        method: "DELETE",
        body: stream_ids,
      }),
      invalidatesTags: ["Channel"],
    }),

    // Восстановить стримы (bulk)
    bulkRestoreTwitchChannels: builder.mutation<
      { msg: string },
      string[]
    >({
      query: (stream_ids) => ({
        url: `${baseURLChannels}/bulk_restore`,
        method: "POST",
        body: stream_ids,
      }),
      invalidatesTags: ["Channel"],
    }),

    // Забронировать стрим
    bookTwitchChannel: builder.mutation<
      { msg: string },
      string
    >({
      query: (stream_id) => ({
        url: `${baseURLChannels}/${stream_id}/book`,
        method: "POST",
      }),
      invalidatesTags: (result, error, stream_id) => [{ type: "Channel", id: stream_id }],
    }),

    // Отменить бронь стрима
    unbookTwitchChannel: builder.mutation<
      { msg: string },
      string
    >({
      query: (stream_id) => ({
        url: `${baseURLChannels}/${stream_id}/unbook`,
        method: "POST",
      }),
      invalidatesTags: (result, error, stream_id) => [{ type: "Channel", id: stream_id }],
    }),

    // Заблокировать запрос на стрим
    blockRequestTwitchChannel: builder.mutation<
      { msg: string },
      string
    >({
      query: (stream_id) => ({
        url: `${baseURLChannels}/${stream_id}/block_request`,
        method: "POST",
      }),
      invalidatesTags: (result, error, stream_id) => [{ type: "Channel", id: stream_id }],
    }),

    // Разблокировать запрос на стрим
    unblockRequestTwitchChannel: builder.mutation<
      { msg: string },
      string
    >({
      query: (stream_id) => ({
        url: `${baseURLChannels}/${stream_id}/unblock_request`,
        method: "POST",
      }),
      invalidatesTags: (result, error, stream_id) => [{ type: "Channel", id: stream_id }],
    }),

    // Получить всех стримеров
    getTwitchStreamers: builder.query<
      // IPage<ITwitchStreamer>,
      IPage<any>,
      Partial<IPagination>
    >({
      query: (params) => ({
        url: `${baseURL}/streamers`,
        method: "GET",
        params,
      }),
      providesTags: ["Channel"],
    }),

    // Экспортировать стримеров
    exportTwitchStreamers: builder.query<Blob, Partial<IPagination>>({
      query: (params) => ({
        url: `${baseURL}/streamers/export`,
        method: "GET",
        params,
        responseHandler: (response) => response.blob(),
      }),
    }),

    // Получить стримера по ID
    // getTwitchStreamerById: builder.query<ITwitchStreamer, string>({
      getTwitchStreamerById: builder.query<any, string>({
      query: (streamer_id) => ({
        url: `${baseURL}/streamers/${streamer_id}`,
        method: "GET",
      }),
      providesTags: (result, error, streamer_id) => [{ type: "Channel", id: streamer_id }],
    }),
  }),
});

