import React from 'react'
import { TwitchStream } from '../TwitchStream'
import { ITwitchChannel } from '../../../../types/twitch';

// тут мапаются стримы и выводятся стримы на фронт

interface IProps {
  channels: Array<ITwitchChannel>;
  selectedChannels: Array<number>;
  isAllToggled: boolean;
  setIsAllToggledHandler: () => void;
  toggleSelectedChannelHandler: (id: number) => void;
  onDelete: (id: number) => void;
  onBook: (id: number) => void;
  onUnBook: (id: number) => void;
  onRestore: (id: number) => void;
}

export const TwitchStreamsTable: React.FC<IProps> = React.memo((props) => {
  const {
    channels,
    selectedChannels,
    isAllToggled,
    setIsAllToggledHandler,
    toggleSelectedChannelHandler,
    onDelete,
    onBook,
    onUnBook,
    onRestore,
  } = props;

  return (
    <div className="table table_twitch">
      <div className="table__main">
        <div className="table__head">
          <div className="table__head-row">
            <div
              className="table__head-cell table__head-cell_22 table__head-cell_mob-top table__head-cell_check"
              data-head
            >
              <div className="table__checkbox checkbox">
                <label className="checkbox__label">
                  <input
                    checked={isAllToggled}
                    onChange={setIsAllToggledHandler}
                    className="checkbox__input"
                    type="checkbox"
                  />
                  <span className="checkbox__icon"></span>
                </label>
              </div>
              <span className="table__label">Название канала</span>
            </div>

            <div className="table__head-cell table__head-cell_21 table__head-cell_hide-tablet">
              <span className="table__label">Страна профиля</span>
            </div>
            <div className="table__head-cell table__head-cell_22 table__head-cell_hide-tablet">
              <span className="table__label">Дата регистрации</span>
            </div>
            <div className="table__head-cell table__head-cell_29 table__head-cell_hide-tablet">
              <span className="table__label">Теги</span>
            </div>
            <div className="table__head-cell table__head-cell_30 table__head-cell_hide-tablet">
              <span className="table__label">Субкультуры</span>
            </div>
            <div
              className="table__head-cell table__head-cell_31 table__head-cell_mob-top"
              data-head
            >
              <span className="table__label">Действия</span>
            </div>
          </div>
        </div>

        <div className="table__body">
          {(channels || []).map((channel) => (
            <TwitchStream
              key={channel.stream_id}
              isSelected={selectedChannels.includes(channel.stream_id)}
              toggleSelectedChannel={toggleSelectedChannelHandler}
              channel={channel}
              onDelete={onDelete}
              onRestore={onRestore}
              onBook={onBook}
              onUnBook={onUnBook}
            />
          ))}
        </div>
      </div>
    </div>
  );
});
