import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {ISelectComponentProps, ISelectOption} from "../../../types/ui";
import {selectPipes} from "../../../pipes/select/select";


export const SelectComponent: React.FC<ISelectComponentProps> = React.memo(({
    options = [],
    onChange,
    currentValue,
    canBeNull = false,
    placeholder = "Выберите",
    disabled = false
}) => {
    const [selectedOption, setSelectedOption] = useState<ISelectOption | null>(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const modifiedOptions = canBeNull ? [{ label: "Все", value: null }, ...options] : options;

    useEffect(() => {
        if (modifiedOptions && modifiedOptions.length > 0) {
            const optionValue = modifiedOptions.find(option => option.value === currentValue);
            setSelectedOption(optionValue || null);
        }
    }, [currentValue, modifiedOptions]);

    return (
        <Select
            menuPortalTarget={document.body}
            styles={{
                control: base => ({
                    ...base,
                    height: "100%",
                    width: "200px"
                }),
                menuPortal: base => ({
                    ...base,
                    zIndex: 9999
                })
            }}
            placeholder={placeholder}
            isDisabled={disabled}
            className="select-component"
            value={selectedOption}
            options={modifiedOptions}
            onChange={(e) => {
                setSelectedOption(e);
                onChange(e ? e.value : null);
            }}
        />
    );
});


export const BuildSelect = <T extends {}>(
    items: Array<T> | undefined,
    current_value: string | null,
    onChange: (value: string | null) => void,
    label: string,
    field_name: string
) => {
    return <div className="main-filters__col">
        <div className="main-filters__input-wrap input-wrap input-wrap_small-mob">
            <label className="top-label">{label}</label>
            <SelectComponent
                currentValue={current_value}
                onChange={onChange}
                options={selectPipes.options(items ?? [], field_name, field_name)}
                canBeNull={true}
            />
        </div>
    </div>
}
